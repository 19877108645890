(function() {
    'use strict';

    angular.module('customerVault.insight')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'insight',
            url: '/insight/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'insight.home',
            url: '/:userId/:id',
            templateUrl: 'view/insight.index',
            data: {
                sessionCheck: true
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ScientiaMobile - InSight'});
            }
        });
    }
})();

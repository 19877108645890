(function() {
    'use strict';

    angular.module('customerVault.capabilities')
        .config(states);

        states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

        function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
            $locationProvider.html5Mode(true);
            $interpolateProvider.startSymbol('<%');
            $interpolateProvider.endSymbol('%>');

            var $window = $windowProvider.$get();

            $provide.decorator('$uiViewScroll', function($delegate) {
                return function(uiViewElement) {
                    $window.scrollTo(0, 0);
                };
            });

            $stateProvider.state({
                name: 'capabilities',
                url: '/capabilities',
                templateUrl: 'view/capabilities.index',
                data: {
                    sessionCheck: true,
                    allow: true
                },
                controller: 'WurflCapabilitiesController',
                controllerAs: 'vm'
            });
        }
})();

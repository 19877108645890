(function() {
    'use strict';

    angular.module('customerVault.site')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'site',
            url: '/site/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'site.evaluation',
            url: '/evaluation?forapi',
            templateUrl: 'view/site.evaluation',
            data: {
                sessionCheck: true,
                allow: true
            },
            params: {
                forapi: {
                    value: null,
                    squash: true
                }
            },
            onEnter: function($window) {
                $window.location.href = 'https://www.scientiamobile.com/trial-request/';
            }
        });
    }
})();

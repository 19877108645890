(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'cloud',
            url: '/cloud/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        }).state({
            name: 'cloud.signup',
            url: '/signup?plan',
            templateUrl: 'view/cloud.signup',
            data: {
                sessionCheck: true,
                allow: true
            },
            params: {
                plan: {
                    value: null,
                    squash: true
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Cloud Signup'});
            }
        }).state({
            name: 'cloud.home',
            url: '/:id',
            templateUrl: 'view/cloud.home',
            data: {
                sessionCheck: true
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Cloud Account Settings'});
            }
        }).state({
            name: 'cloud.home.apikeys',
            url: '/apikeys',
            data: {
                sessionCheck: true
            },
            views: {
                'apikeys': {
                    templateUrl: 'view/cloud.subpages.api'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Cloud Account Settings'});
            }
        }).state({
            name: 'cloud.home.usage',
            url: '/usage',
            data: {
                sessionCheck: true
            },
            views: {
                'usage': {
                    templateUrl: 'view/cloud.subpages.usage'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Cloud Account Settings'});
            }
        }).state({
            name: 'cloud.home.billinghistory',
            url: '/billinghistory',
            data: {
                sessionCheck: true
            },
            params: {
                reload: true
            },
            views: {
                'billinghistory': {
                    templateUrl: "view/cloud.subpages.billinghistory"
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Cloud Account Settings'});
            }
        }).state({
            name: 'cloud.home.billingcontacts',
            url: '/billingcontacts',
            data: {
                sessionCheck: true
            },
            views: {
                'billingcontacts': {
                    templateUrl: 'view/cloud.subpages.billingcontacts'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Cloud Account Settings'});
            }
        }).state({
            name: 'cloud.home.updatebilling',
            url: '/updatebilling',
            data: {
                sessionCheck: true
            },
            views: {
                'updatebilling': {
                    templateUrl: 'view/cloud.subpages.billing'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Cloud Account Settings'});
            }
        }).state({
            name: 'cloud.home.planinfo',
            url: '/planinfo',
            data: {
                sessionCheck: true
            },
            views: {
                'planinfo': {
                    templateUrl: 'view/cloud.subpages.plan_info'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Cloud Account Settings'});
            }
        }).state({
            name: 'cloud.home.changeplan',
            url: '/changeplan/:plan',
            data: {
                sessionCheck: true
            },
            views: {
                'changeplan': {
                    templateUrl: 'view/cloud.subpages.change_plan'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Cloud Account Settings'});
            }
        }).state({
            name: 'cloud.home.capabilities',
            url: '/capabilities',
            data: {
                sessionCheck: true
            },
            views: {
                'capabilities': {
                    templateUrl: 'view/cloud.subpages.capabilities'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Cloud Account Settings'});
            }
        });
    }
})();

(function() {
    'use strict';

    angular.module('customerVault.license')
        .config(states);

        states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

        function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
            $locationProvider.html5Mode(true);
            $interpolateProvider.startSymbol('<%');
            $interpolateProvider.endSymbol('%>');

            var $window = $windowProvider.$get();

            $provide.decorator('$uiViewScroll', function($delegate) {
                return function(uiViewElement) {
                    $window.scrollTo(0, 0);
                };
            });

            $stateProvider.state({
                name: 'license',
                url: '/license/app',
                abstract: true,
                template: '<ui-view/>',
                data: {
                    sessionCheck: true
                }
            })
            .state({
                name: 'license.home',
                url: '/:id',
                templateUrl: 'view/license.home',
                data: {
                    sessionCheck: true
                }
            }).state({
                name: 'license.home.guide',
                data: {
                    sessionCheck: true
                },
                views: {
                    guide: {
                        templateUrl: 'view/license.subpages.gettingstarted'
                    }
                }
            }).state({
                name: 'license.home.capabilities',
                url: '/capabilities',
                data: {
                    sessionCheck: true
                },
                views: {
                    capabilities: {
                        templateUrl: 'view/license.subpages.capabilities'
                    }
                }
            }).state({
                name: 'license.home.license',
                url: '/license',
                data: {
                    sessionCheck: true
                },
                views: {
                    license: {
                        templateUrl: 'view/license.subpages.license'
                    }
                }
            });
        }
})();

(function() {
    'use strict';

    angular.module('customerVault.quwurfl')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'quwurfl',
            url: '/quwurfl/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        }).state({
            name: 'quwurfl.home',
            url: '/:id',
            templateUrl: 'view/quwurfl.home',
            data: {
                sessionCheck: true
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Query API Account Settings'});
            }
        }).state({
            name: 'quwurfl.home.apikeys',
            url: '/apikeys',
            data: {
                sessionCheck: true
            },
            views: {
                'apikeys': {
                    templateUrl: 'view/quwurfl.subpages.api'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Query API Account Settings'});
            }
        }).state({
            name: 'quwurfl.home.domains',
            url: '/domains',
            data: {
                sessionCheck: true
            },
            views: {
                'domains': {
                    templateUrl: 'view/quwurfl.subpages.domain'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'WURFL Query API Account Settings'});
            }
        })
        ;
    }
})();

(function() {
    'use strict';

    angular.module('customerVault.home')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'myaccount.products',
            url: '/products',
            data: {
                sessionCheck: true
            },
            views: {
                products: {
                    templateUrl: 'view/home.tabs.products'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ScientiaMobile - Account'});
            }
        }).state({
            name: 'myaccount.licenses',
            url: '/licenses',
            data: {
                sessionCheck: true
            }
        }).state({
            name: 'myaccount.subscriptions',
            url: '/subscriptions',
            data: {
                sessionCheck: true
            }
        }).state({
            name: 'myaccount.trials',
            url: '/trials',
            data: {
                sessionCheck: true
            },
            views: {
                subscriptions: {
                    templateUrl: 'view/home.tabs.trials'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ScientiaMobile - Account'});
            }
        })
        // .state({
        //     name: 'myaccount.orders',
        //     url: '/orders',
        //     data: {
        //         sessionCheck: true
        //     },
        //     views: {
        //         orders: {
        //             templateUrl: 'view/home.tabs.orders'
        //         }
        //     },
        //     onEnter: function($rootScope) {
        //         $rootScope.$broadcast('setTitle', {title: 'ScientiaMobile - Orders'});
        //     }
        // })

        //============================================
        // Account pages are for admin viewing of user
        //=============================================
        .state({
            name: 'account.products',
            url: '/products',
            data: {
                sessionCheck: true
            },
            views: {
                products: {
                    templateUrl: 'view/home.tabs.products'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ScientiaMobile - Account'});
            }
        }).state({
            name: 'account.trials',
            url: '/trials',
            data: {
                sessionCheck: true
            },
            views: {
                subscriptions: {
                    templateUrl: 'view/home.tabs.trials'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ScientiaMobile - Account'});
            }
        }).state({
            name: 'account.licenses',
            url: '/licenses',
            data: {
                sessionCheck: true
            }
        }).state({
            name: 'account.subscriptions',
            url: '/subscriptions',
            data: {
                sessionCheck: true
            }
        })
        // .state({
        //     name: 'account.orders',
        //     url: '/orders',
        //     data: {
        //         sessionCheck: true
        //     },
        //     views: {
        //         orders: {
        //             templateUrl: 'view/home.tabs.orders'
        //         }
        //     },
        //     onEnter: function($rootScope) {
        //         $rootScope.$broadcast('setTitle', {title: 'ScientiaMobile - Orders'});
        //     }
        // })
        .state({
            name: 'passwordreset',
            url: '/password/reset/:hash',
            data: {
                sessionCheck: true,
                allow: true
            },
            templateUrl: 'view/home.passwordreset',
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ScientiaMobile - Forgot Password'});
            }
        });
    }
})();

(function() {
    'use strict';

    angular.module('customerVault.impersonate')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'impersonate',
            url: '/impersonate/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true,
                allow: true
            }
        })
        .state({
            name: 'impersonate.login',
            url: '/login?token',
            data: {
                sessionCheck: true,
                allow: true
            },
            params: {
                token: {
                    value: null
                }
            },
            controller: 'ImpersonateLoginController',
            reloadOnSearch: false
        })
        .state({
            name: 'impersonate.admin',
            url: '/admin?session&access_token',
            data: {
                sessionCheck: true,
                allow: true
            },
            params: {
                session: {
                    value: null
                },
                access_token: {
                    value: null
                }
            },
            controller: 'ImpersonateAdminController',
            reloadOnSearch: false
        });
    }
})();

(function() {
    'use strict';

    angular.module('authApp')
        .config(states);

    states.$inject = ['$locationProvider', '$stateProvider'];

    function states($locationProvider, $stateProvider) {
        $locationProvider.html5Mode(true);

        $stateProvider.state({
            name: 'login',
            url: '/login',
            templateUrl: 'view/home.login',
            data: {
                sessionCheck: true
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'Login'});
            }
        });
    }
})();

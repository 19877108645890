(function() {
    'use strict';

    angular.module('customerVault')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'myaccount',
            url: '/myaccount',
            templateUrl: 'view/home.myaccount',
            data: {
                sessionCheck: true
            },
            controller: 'MyAccountController',
            controllerAs: 'vm'
        })
        .state({
            name: 'account',
            url: '/account/:id',
            templateUrl: 'view/home.myaccount',
            data: {
                sessionCheck: true
            },
            controller: 'MyAccountController',
            controllerAs: 'vm'
        })
        .state({
            name: 'register',
            url: '/register',
            templateUrl: 'view/home.register',
            data: {
                sessionCheck: true,
                allow: true
            },
            controller: 'RegistrationController',
            controllerAs: 'vm',
            resolve: {
                evaluation: function($stateParams) {
                    return $stateParams.evaluation;
                }
            },
            params: {
                evaluation: false
            }
        })
        .state({
            name: 'verify',
            url: '/user/verify/:link',
            templateUrl: 'view/home.verify',
            data: {
                sessionCheck: true,
                allow: true
            },
            controller: 'VerifyController',
            controllerAs: 'vm'
        })
        .state({
            name: 'zendesk',
            url: '/sso/zendeskAuth/?return_to&brand_id&locale_id&timestamp',
            controller: 'ZendeskController',
            controllerAs: 'vm',
            data: {
                sessionCheck: true,
                allow: true
            },
            params: {
                return_to: {
                    value: null,
                    squash: true
                },
                brand_id: {
                    value: null,
                    squash: true
                },
                locale_id: {
                    value: null,
                    squash: true
                },
                timestamp: {
                    value: null,
                    squash: true
                }
            },
            templateUrl: 'view/home.zendesk_auth'
        })
        .state({
            name: 'forbidden',
            url: '/forbidden',
            templateUrl: 'view/errors.403',
            data: {
                sessionCheck: false
            }
        }).state("otherwise", { // Show 404 without changing path
            url: "*path",
            templateUrl: "view/errors.404",
            controller: 'PageNotFoundController'
        });
    }
})();

(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .config(states);

        states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

        function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
            $locationProvider.html5Mode(true);
            $interpolateProvider.startSymbol('<%');
            $interpolateProvider.endSymbol('%>');

            var $window = $windowProvider.$get();

            $provide.decorator('$uiViewScroll', function($delegate) {
                return function(uiViewElement) {
                    $window.scrollTo(0, 0);
                };
            });

            $stateProvider.state({
                name: 'wurfljs',
                url: '/wurfljs/app',
                abstract: true,
                template: '<ui-view/>',
                data: {
                    sessionCheck: true
                }
            }).state({
                name: 'wurfljs.signup',
                url: '/signup?plan',
                templateUrl: 'view/wurfljs.signup',
                data: {
                    sessionCheck: true,
                    allow: true
                },
                params: {
                    plan: {
                        value: null,
                        squash: true
                    }
                },
                onEnter: function($rootScope) {
                    $rootScope.$broadcast('setTitle', {title: 'WURFL.js Signup'});
                }
            }).state({
                name: 'wurfljs.home',
                url: '/:id',
                templateUrl: 'view/wurfljs.home',
                data: {
                    sessionCheck: true
                },
                onEnter: function($rootScope) {
                    $rootScope.$broadcast('setTitle', {title: 'WURFL.js Account Settings'});
                }
            }).state({
                name: 'wurfljs.home.summary',
                url: '/usage',
                data: {
                    sessionCheck: true
                },
                views: {
                    'summary': {
                        templateUrl: 'view/wurfljs.subpages.usage'
                    }
                },
                onEnter: function($rootScope) {
                    $rootScope.$broadcast('setTitle', {title: 'WURFL.js Account Settings'});
                }
            }).state({
                name: 'wurfljs.home.gettingstarted',
                url: '/gettingstarted',
                data: {
                    sessionCheck: true
                },
                views: {
                    'guide': {
                        templateUrl: 'view/wurfljs.subpages.guide'
                    }
                },
                onEnter: function($rootScope) {
                    $rootScope.$broadcast('setTitle', {title: 'WURFL.js Account Settings'});
                }
            }).state({
                name: 'wurfljs.home.capabilities',
                url: '/capabilities',
                data: {
                    sessionCheck: true
                },
                views: {
                    'capabilities': {
                        templateUrl: 'view/wurfljs.subpages.capabilities'
                    }
                },
                onEnter: function($rootScope) {
                    $rootScope.$broadcast('setTitle', {title: 'WURFL.js Account Settings'});
                }
            }).state({
                name: 'wurfljs.home.domains',
                url: '/domains',
                data: {
                    sessionCheck: true
                },
                views: {
                    'domains': {
                        templateUrl: 'view/wurfljs.subpages.domains'
                    }
                },
                onEnter: function($rootScope) {
                    $rootScope.$broadcast('setTitle', {title: 'WURFL.js Account Settings'});
                }
            }).state({
                name: 'wurfljs.home.cnames',
                url: '/cnames',
                data: {
                    sessionCheck: true
                },
                views: {
                    'cnames': {
                        templateUrl: 'view/wurfljs.subpages.cnames'
                    }
                },
                onEnter: function($rootScope) {
                    $rootScope.$broadcast('setTitle', {title: 'WURFL.js Account Settings'});
                }
            }).state({
                name: 'wurfljs.home.billinghistory',
                url: '/billinghistory',
                data: {
                    sessionCheck: true
                },
                params: {
                    reload: true
                },
                views: {
                    'billinghistory': {
                        templateUrl: 'view/wurfljs.subpages.billinghistory'
                    }
                },
                onEnter: function($rootScope) {
                    $rootScope.$broadcast('setTitle', {title: 'WURFL.js Account Settings'});
                }
            }).state({
                name: 'wurfljs.home.updatebilling',
                url: '/updatebilling',
                data: {
                    sessionCheck: true
                },
                views: {
                    'updatebilling': {
                        templateUrl: 'view/wurfljs.subpages.updatebilling'
                    }
                },
                onEnter: function($rootScope) {
                    $rootScope.$broadcast('setTitle', {title: 'WURFL.js Account Settings'});
                }
            }).state({
                name: 'wurfljs.home.planinfo',
                url: '/planinfo',
                data: {
                    sessionCheck: true
                },
                views: {
                    'planinfo': {
                        templateUrl: 'view/wurfljs.subpages.planinfo'
                    }
                },
                onEnter: function($rootScope) {
                    $rootScope.$broadcast('setTitle', {title: 'WURFL.js Account Settings'});
                }
            }).state({
                name: 'wurfljs.home.changeplan',
                url: '/changeplan/:plan',
                data: {
                    sessionCheck: true
                },
                views: {
                    'changeplan': {
                        templateUrl: 'view/wurfljs.subpages.changeplan'
                    }
                },
                onEnter: function($rootScope) {
                    $rootScope.$broadcast('setTitle', {title: 'WURFL.js Account Settings'});
                }
            });
        }
})();

(function() {
    'use strict';

    angular.module('customerVault.filters')
        .filter('toInteger', toInteger);

    function toInteger() {
        return function(num) {
            return parseInt(num);
        }
    };
})();

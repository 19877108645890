(function() {
    'use strict';

    angular.module('customerVault.filters')
        .filter('basicAuthHeader', basicAuthHeader);

    function basicAuthHeader() {
        return function(key) {
            return btoa(key.api_username+':'+key.api_key);
        }
    };
})();

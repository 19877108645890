(function() {
    'use strict';

    angular.module('customerVault.ie')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'ie',
            url: '/imageengine/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        }).state({
            name: 'ie.signup',
            url: '/signup?plan',
            templateUrl: 'view/imageengine.signup',
            data: {
                sessionCheck: true,
                allow: true
            },
            params: {
                plan: {
                    value: null,
                    squash: true
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ImageEngine Account Signup'});
            }
        }).state({
            name: 'ie.signupcomplete',
            url: '/signupcomplete',
            templateUrl: 'view/imageengine.signup_complete',
            data: {
                sessionCheck: true
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ImageEngine Automated Account Signup Complete'});
            },
            resolve: {
                fromSignup: function($stateParams) {
                    return $stateParams.fromSignup;
                }
            },
            params: {
                fromSignup: false
            }
        }).state({
            name: 'ie.trial',
            url: '/trial/:link',
            templateUrl: 'view/imageengine.trial',
            data: {
                sessionCheck: true,
                allow: true
            }
        }).state({
            name: 'ie.trialcomplete',
            url: '/trial/trialcomplete/:id',
            templateUrl: 'view/imageengine.trialcomplete',
            data: {
                sessionCheck: true
            },
            resolve: {
                fromTrial: function($stateParams) {
                    return $stateParams.fromTrial;
                },
                error: function($stateParams) {
                    return $stateParams.error;
                }
            },
            params: {
                fromTrial: false,
                error: false
            }
        }).state({
            name: 'ie.home',
            url: '/:id',
            templateUrl: 'view/imageengine.home',
            data: {
                sessionCheck: true
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ImageEngine Account Settings'});
            }
        }).state({
            name: 'ie.home.summary',
            url: '/summary',
            data: {
                sessionCheck: true
            },
            views: {
                'summary': {
                    templateUrl: 'view/imageengine.subpages.summary'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ImageEngine Account Settings'});
            }
        }).state({
            name: 'ie.home.cache',
            url: '/cache-purging',
            data: {
                sessionCheck: true
            },
            views: {
                'cache': {
                    templateUrl: 'view/imageengine.subpages.cache'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ImageEngine Account Settings'});
            }
        }).state({
            name: 'ie.home.billinghistory',
            url: '/billinghistory',
            data: {
                sessionCheck: true
            },
            views: {
                'billinghistory': {
                    templateUrl: 'view/imageengine.subpages.billinghistory'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ImageEngine Account Settings'});
            }
        }).state({
            name: 'ie.home.billinginfo',
            url: '/billinginfo',
            data: {
                sessionCheck: true
            },
            views: {
                'billinginfo': {
                    templateUrl: 'view/imageengine.subpages.billinginfo'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ImageEngine Account Settings'});
            }
        }).state({
            name: 'ie.home.planinfo',
            url: '/planinfo',
            data: {
                sessionCheck: true
            },
            views: {
                'planinfo': {
                    templateUrl: 'view/imageengine.subpages.planinfo'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ImageEngine Account Settings'});
            }
        }).state({
            name: 'ie.home.upgrade',
            url: '/upgrade/:plan',
            data: {
                sessionCheck: true
            },
            views: {
                'upgrade': {
                    templateUrl: 'view/imageengine.subpages.upgrade'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ImageEngine Account Settings'});
            }
        }).state({
            name: 'ie.payment',
            url: '/payment/:link',
            data: {
                sessionCheck: true
            },
            templateUrl: 'view/imageengine.payment',
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ImageEngine - Add Payment Information'});
            }
        }).state({
            name: 'ie.home.statistics',
            url: '/statistics',
            data: {
                sessionCheck: true
            },
            views: {
                'statistics': {
                    templateUrl: 'view/imageengine.subpages.statistics'
                }
            },
            onEnter: function($rootScope) {
                $rootScope.$broadcast('setTitle', {title: 'ImageEngine Account Settings'});
            }
        });
    }
})();
